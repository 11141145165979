export const Crossword = () => {
    return (
      <div className="crossword-div">
  <div style={{ width: "100%", height: "800px", border: "none" }}>
        <iframe
          src="https://lucianatux.github.io/crucigrama/"
          style={{ width: "100%", height: "100%", border: "none" }}
          title="Wordle"
        />
      </div>
      </div>
    );
  };
  